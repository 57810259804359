// client/src/components/ChatMessage.js

import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

const ChatMessage = ({ index, message, onUpdateMessage, onDeleteMessage }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(
    JSON.stringify(message, null, 2)
  );

  const saveEditedMessage = () => {
    let updatedMessage;
    try {
      updatedMessage = JSON.parse(editedContent);
    } catch (error) {
      alert('Invalid JSON format');
      return;
    }
    onUpdateMessage(index, updatedMessage);
    setIsEditing(false);
  };

  const role = message.role || 'Unknown';
  const defaultCollapsed = role === 'tool' || role === 'system';
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  const messageStyle =
    role === 'user'
      ? 'bg-blue-500 text-white'
      : role === 'assistant'
      ? 'bg-gray-200 text-black'
      : 'bg-yellow-100 text-black';

  const renderContent = () => {
    if (typeof message.content === 'string') {
      return <ReactMarkdown>{message.content || '[No Content]'}</ReactMarkdown>;
    } else if (Array.isArray(message.content)) {
      return message.content.map((item, idx) => {
        if (item.type === 'text') {
          return (
            <ReactMarkdown key={idx}>
              {item.text || '[No Content]'}
            </ReactMarkdown>
          );
        } else if (item.type === 'image_url') {
          return (
            <img
              key={idx}
              src={item.image_url.url}
              alt="User uploaded"
              className="max-w-full h-auto my-2"
            />
          );
        } else {
          return null;
        }
      });
    } else {
      return <pre>{JSON.stringify(message.content, null, 2)}</pre>;
    }
  };

  return (
    <div className={`p-2 rounded-lg my-1 ${messageStyle}`}>
      <div
        className="font-bold cursor-pointer"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {role} {isCollapsed ? '[+]' : '[-]'}
      </div>
      {!isCollapsed &&
        (!isEditing ? (
          <>
            {renderContent()}
            <button onClick={() => setIsEditing(true)}>Edit</button>
            <button onClick={() => onDeleteMessage(index)}>Delete</button>
          </>
        ) : (
          <>
            <textarea
              value={editedContent}
              onChange={(e) => setEditedContent(e.target.value)}
              className="w-full p-2 border rounded text-black"
              rows={10}
            />
            <button className="mr-1" onClick={saveEditedMessage}>Save</button>
            <button onClick={() => setIsEditing(false)}>Cancel</button>
          </>
        ))}
    </div>
  );
};

export default ChatMessage;
