// client/src/components/ObjectEditor.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useError } from '../contexts/ErrorContext';

function ObjectEditor({component}) {
    const { objectId } = useParams();
    const { showError } = useError();
    const [object, setObject] = useState(null);
    const [content, setContent] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [savingCounter, setSavingCounter] = useState(0);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (!content) return;
        let newObject;
        try {
            newObject = JSON.parse(content);
        }
        catch (error) {
            // the object is not yet ready
            return;
        }
        if (JSON.stringify(newObject, null, 2) === JSON.stringify(object, null, 2)) return;
        setObject(newObject);
    }, [content]);
    useEffect(() => {
        if (!object) return;
        if (JSON.stringify(object, null, 2) === content) return;
        setContent(JSON.stringify(object, null, 2));
    }, [object]);

    const saveEdits = async () => {
        setSavingCounter((prev) => prev + 1);
        try {
            await axios.put(
                `/api/objects/${objectId}`,
                object,
                { withCredentials: true }
            );
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating object:', error);
            showError('Error updating object:', error);
        }
        setSavingCounter((prev) => prev - 1);
    };

    useEffect(() => {
        if (savingCounter <= 0) setIsSaving(false);
        else setIsSaving(true);
    }, [savingCounter]);

    const handleSave = async (e) => {
        let updated;
        try {
            updated = JSON.parse(content);
        } catch (error) {
            alert('Invalid JSON format');
            return;
        }

        await saveEdits();
        setIsEditing(false);
        setObject(updated);
    };

    return (isEditing ? (
        <>
            <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="w-full p-2 border rounded text-black"
                rows={30}
            />
            <button onClick={handleSave} className="ml-2 btn btn-primary">
                Save Object
            </button>
            <button
                onClick={() => setIsEditing(false)}
                className="ml-2 btn btn-secondary"
            >
                Cancel
            </button>
        </>
    ) : (
        <>
            <button
                onClick={() => setIsEditing(true)}
                className="btn btn-primary text-blue-500 mb-4"
            >
                Edit Object
            </button>
            {component(object, setObject, objectId, saveEdits, isSaving)}
        </>
    ));
}

export default ObjectEditor;