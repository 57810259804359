// client/src/components/ConversationPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AIChat from './AIChat';
import { useParams, useNavigate } from 'react-router-dom';
import { getLocalDate } from '../utils/webUtils';
import { useError } from '../contexts/ErrorContext';

function ConversationPage({ conversationId, conversation, setConversation, saveEdits, isSaving }) {
  const navigate = useNavigate();
  const [configCollapsed, setConfigCollapsed] = useState(true);
  const [availableTools, setAvailableTools] = useState({});
  const { showError } = useError();

  const loadConversation = async () => {
    if (conversationId !== 'new') {
      await axios
        .get(`/api/objects/${conversationId}`, { withCredentials: true })
        .then((response) => {
          setConversation(response.data.object);
        })
        .catch((error) => {
          console.error('Error fetching conversation:', error);
          showError('Error fetching conversation:', error);
        });
    }
  };

  useEffect(() => {
    if (conversationId === 'new') {
      axios
        .post(
          '/api/conversations',
          { date: getLocalDate() },
          { withCredentials: true }
        )
        .then((response) => {
          const newConversation = response.data;
          navigate(`/conversations/${newConversation.id}`, { replace: true });
        })
        .catch((error) => {
          console.error('Error creating new conversation:', error);
          showError('Error creating new conversation:', error);
        });
    } else {
      loadConversation();
    }
  }, [conversationId, navigate]);

  useEffect(() => {
    axios
      .get('/api/calendars/lists', { withCredentials: true })
      .then((response) => {
        if (!conversation) { return; }
        if (!conversation.configuration.calendars) {
          conversation.configuration.calendars = response.data.map(cal => ({
            id: cal.id,
            title: cal.summary,
            enabled: false
          }));
          setConversation(oldConversation => ({ ...oldConversation, configuration: { ...oldConversation.configuration, calendars: conversation.configuration.calendars } }));
        }
      })
      .catch((error) => {
        console.error('Error fetching calendar lists:', error);
        showError('Error fetching calendar lists:', error);
      });
  }, [conversation]);

  useEffect(() => {
    axios
      .get('/api/tasks/lists', { withCredentials: true })
      .then((response) => {
        if (conversation === null) { return; }
        if (conversation.configuration === undefined) {
          conversation.configuration = {};
        }
        if (conversation.configuration.taskLists === undefined) {
          conversation.configuration.taskLists = response.data.map(list => ({
            id: list.id,
            title: list.title,
            enabled: true
          }));
          setConversation(oldConversation => ({ ...oldConversation, configuration: { ...oldConversation.configuration, taskLists: conversation.configuration.taskLists } }));
        }
      })
      .catch((error) => {
        console.error('Error fetching task lists:', error);
        showError('Error fetching task lists:', error);
      });
  }, [conversation]);

  useEffect(() => {
    axios
      .get('/api/ai/tools', { withCredentials: true })
      .then(async (response) => {
        setAvailableTools(response.data.tools);

        if (!conversation) { return; }
        if (!conversation.configuration.enabledTools) {
          conversation.configuration.enabledTools = {};
        }

        const updatedTools = {};
        let hasChanges = false;
        response.data.tools.forEach(({ name }) => {
          if (conversation.configuration.enabledTools[name] === undefined) {
            hasChanges = true;
          }
          updatedTools[name] =
            conversation.configuration.enabledTools[name] !== undefined
              ? conversation.configuration.enabledTools[name]
              : true;
        });

        if (hasChanges) {
          conversation.configuration.enabledTools = updatedTools;
          setConversation(oldConversation => ({ ...oldConversation, configuration: { ...oldConversation.configuration, enabledTools: updatedTools } }));
          await saveEdits();
        }
      })
      .catch((error) => {
        console.error('Error fetching tools:', error);
        showError('Error fetching tools:', error);
      });
  }, [conversation]);

  const handleChange = async (field, value) => {
    conversation[field] = value;
    setConversation({ ...conversation });
  };

  const onSave = async () => {
    await saveEdits();
    alert('Conversation saved successfully!');
  };

  const toggleCalendar = async (calendarId) => {
    const cal = conversation.configuration.calendars.find((c) => c.id === calendarId);
    cal.enabled = !cal.enabled;
    setConversation({ ...conversation });
    await saveEdits();
  };

  const toggleTaskList = async (taskListId) => {
    const list = conversation.configuration.taskLists.find((l) => l.id === taskListId);
    list.enabled = !list.enabled;
    setConversation({ ...conversation });
    await saveEdits();
  };

  const toggleTool = async (toolName) => {
    conversation.configuration.enabledTools[toolName] =
      !conversation.configuration.enabledTools[toolName];
    setConversation({ ...conversation });
    await saveEdits();
  };

  const onUpdateMessage = async (key, message) => {
    conversation.messages[key] = message;
    setConversation({ ...conversation });
    await saveEdits();
    await loadConversation();
  };

  const onDeleteMessage = async (key) => {
    if (conversation.freezePoint !== null && key <= conversation.freezePoint) {
      conversation.freezePoint = Math.max(0, conversation.freezePoint - 1);
    }
    conversation.messages.splice(key, 1);
    setConversation({ ...conversation });
    await saveEdits();
    await loadConversation();
  };

  const addEmptyMessage = async () => {
    conversation.messages.push({ role: 'user', content: '' });
    setConversation({ ...conversation });
    await saveEdits();
    await loadConversation();
  };

  const onToggleFreezePoint = async (freezePoint) => {
    if (freezePoint === null) {
      conversation.freezePoint = null;
    } else {
      conversation.freezePoint = freezePoint;
    }
    setConversation({ ...conversation });
    await saveEdits();
    await loadConversation();
  };

  if (!conversation) {
    return <div>Loading...</div>;
  }

  if (!conversation.configuration?.calendars) {
    conversation.configuration.calendars = [];
  }

  return (
    <div className="conversation-page">
      <>
        <h1
          className="text-2xl font-bold cursor-pointer"
        >
          <input
            type="text"
            value={conversation.name}
            onChange={(e) => handleChange('name', e.target.value)}
            placeholder="Conversation Title"
            className="border p-1 w-full"
          />
        </h1>
        <button className="text-blue-500 hover:underline"
          onClick={onSave}
        >
          Save Name
        </button>
        <div>
          <h2
            className="cursor-pointer font-bold"
            onClick={() => setConfigCollapsed(!configCollapsed)}
          >
            Configurations {configCollapsed ? '[+]' : '[-]'}
          </h2>
          {!configCollapsed && (
            <div>
              <h3>Calendars</h3>
              {!conversation.configuration.calendars || conversation.configuration.calendars.length === 0 ? (
                <p className="text-gray-500">No calendars available</p>
              ) : (
                <ul>
                  {conversation.configuration.calendars.map((cal) => (
                    <li key={cal.id}>
                      <label>
                        <input
                          type="checkbox"
                          checked={cal.enabled}
                          onChange={() => toggleCalendar(cal.id)}
                          className="form-checkbox"
                        />
                        <span>{cal.summary || cal.title}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              )}
              <h3>Task Lists</h3>
              {!conversation.configuration.taskLists || conversation.configuration.taskLists.length === 0 ? (
                <p className="text-gray-500">No task lists available</p>
              ) : (
                <ul>
                  {conversation.configuration.taskLists.map((list) => (
                    <li key={list.id}>
                      <label>
                        <input
                          type="checkbox"
                          checked={list.enabled}
                          onChange={() => toggleTaskList(list.id)}
                          className="form-checkbox"
                        />
                        <span>{list.title}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              )}
              <h3>AI Model</h3>
              <label>
                <input
                  type="text"
                  value={conversation.configuration.model}
                  onChange={async (e) => {
                    conversation.configuration.model = e.target.value;
                    setConversation({ ...conversation });
                    await saveEdits();
                  }}
                />
              </label>
              <h3>Temperature {`0<x<1`}</h3>
              <label>
                <input
                  type="number"
                  value={conversation.configuration.temperature}
                  onChange={async (e) => {
                    if (e.target.value < 0 || e.target.value > 1) { return; }
                    conversation.configuration.temperature = parseFloat(e.target.value);
                    setConversation({ ...conversation });
                    await saveEdits();
                  }}
                />
              </label>
              <div className="tools-section mb-4">
                <h3 className="text-lg font-bold mb-2">Enabled Tools</h3>
                <div className="grid grid-cols-2 gap-2">
                  {availableTools.map(({name, description}) => (
                    <div key={name} className="tool-item border p-2 rounded">
                      <label className="flex items-start space-x-2">
                        <input
                          type="checkbox"
                          checked={conversation.configuration.enabledTools[name] || false}
                          onChange={() => toggleTool(name)}
                          className="mt-1"
                        />
                        <div>
                          <div className="font-medium">{name}</div>
                          <div className="text-sm text-gray-600">{description}</div>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <h3>System Prompt Template</h3>
              <textarea
                value={conversation.configuration.systemPromptTemplate}
                onChange={async (e) => {
                  conversation.configuration.systemPromptTemplate = e.target.value;
                  setConversation({ ...conversation });
                  await saveEdits();
                }}
                rows={10}
                cols={80}
              />
              <p>
                Available placeholders: {'{dateTime}'}, {'{conversationName}'}, {'{conversationStart}'}, {'{memories}'}
              </p>
            </div>
          )}
        </div>
        <div className="mt-2">
          {conversation.labels && conversation.labels.length > 0 && (
            <div className="mb-2">
              <strong>Labels:</strong> {conversation.labels.join(', ')}
            </div>
          )}
        </div>
        <AIChat
          conversation={conversation}
          conversationId={conversation.id}
          refreshConversation={loadConversation}
          onUpdateMessage={onUpdateMessage}
          onDeleteMessage={onDeleteMessage}
          addEmptyMessage={addEmptyMessage}
          isSaving={isSaving}
          onToggleFreezePoint={onToggleFreezePoint}
        />
      </>
    </div>
  );
}

export default ConversationPage;
