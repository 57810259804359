// client/src/components/ConversationItem.js

import React from 'react';
import { Link } from 'react-router-dom';

function ConversationItem({ conversation }) {
  return (
    <div>
      <div className="text-sm text-gray-600">
        <p>Model: {conversation.configuration?.model || 'Unknown'}</p>
        <p>{conversation.ai_summary && `Summary: ${conversation.ai_summary}`}</p>
      </div>
      <Link
        to={`/conversations/${conversation.id}`}
        className="text-blue-500 hover:underline"
      >
        Continue Conversation
      </Link>
    </div>
  );
}

export default ConversationItem;
