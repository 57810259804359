// client/src/components/ObjectsPage.js

import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import ObjectItem from './ObjectItem';

const ObjectsPage = () => {
  const [objects, setObjects] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTags, setSearchTags] = useState(searchParams.get('search') || '');
  const [filteredObjects, setFilteredObjects] = useState([]);
  
  const fetchObjects = async () => {
    try {
      const response = await axios.get('/api/objects', { withCredentials: true });
      // Sort objects by start date. if there is no start date, retain the position in the array, but sort the rest
      
      let responseObjects = response.data.objects || [];
      const objectsWithStartDate = responseObjects.filter(obj => obj.start);
      objectsWithStartDate.sort((a, b) => new Date(b.start.dateTime) - new Date(a.start.dateTime));
      const objectsWithoutStartDate = responseObjects.filter(obj => !obj.start);
      const objects = [...objectsWithStartDate, ...objectsWithoutStartDate];
      
      setObjects(objects);
    } catch (error) {
      console.error('Error fetching objects:', error);
    }
  };

  useEffect(() => {
    fetchObjects();
  }, []);

  useEffect(() => {
    setFilteredObjects(objects);
  }, [objects]);

  const handleTagSearch = () => {
    if (!searchTags) {
      setFilteredObjects(objects);
      return;
    }

    const words = searchTags.split(',').map((word) => word.trim().toLowerCase());
    const filtered = objects.filter((obj) =>
      words.every((word) => obj.type.trim().toLowerCase() == word || 
        obj.labels && obj.labels.map(x => x.trim().toLowerCase()).includes(word))
    );
    setFilteredObjects(filtered);
  };

  useEffect(() => {
    handleTagSearch();
  }, [searchTags]);

  return (
    <div className="objects-page">
      <h1 className="text-2xl font-bold mb-4">Objects</h1>
      <div className="mt-4">
        <h2 className="text-xl font-bold mb-2">Search by Tags</h2>
        <input
          type="text"
          value={searchTags}
          onChange={(e) => setSearchTags(e.target.value)}
          placeholder="Enter types or tags, comma-separated"
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="mt-4">
        {filteredObjects.map((obj) => (
          <ObjectItem key={obj.id} object={obj} />
        ))}
        { objects.length ? (
            filteredObjects.length === 0 && (<p className="text-lg text-gray-600">No objects found</p>)
          ) : (
            <p className="text-lg text-gray-600">Loading...</p>
          )
        }
      </div>
    </div>
  );
};

export default ObjectsPage;
