// client/src/components/ObjectItem.js

import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NoteItem from './NoteItem';
import ConversationItem from './ConversationItem';
import JobItem from './JobItem';

function ObjectItem({ object }) {
  const navigate = useNavigate();

  const onDeleteObject = async () => {
    if (!window.confirm('Are you sure you want to delete this object?')) {
      return;
    }

    try {
      await axios.delete(`/api/objects/${object.id}`, { withCredentials: true });
      // alert('Object deleted successfully!');
      navigate(0);
    } catch (error) {
      console.error('Error deleting object:', error);
    }
  }

  const internalObject = () => {
    if (object.type === 'Note') {
      return <NoteItem note={object} />;
    }
    if (object.type === 'Conversation') {
      return <ConversationItem conversation={object} />;
    }
    if (object.type === 'Job') {
      return <JobItem job={object} />;
    }
    return (
      <div>
        <Link to={`/objects/${object.id}`} className="text-blue-500 hover:underline">
          View Object
        </Link>
      </div>
    );
  };

  return (
    <div className="p-4 border rounded shadow mb-2">
      <h2 className="text-xl font-semibold">{object.name || 'Untitled'}</h2>
      <p>Type: {object.type}</p>
      {object.labels && object.labels.length > 0 && (
        <p>Labels: {object.labels.join(', ')}</p>
      )}

      {internalObject()}

      <button
        onClick={onDeleteObject}
        className="text-red-500 hover:underline"
      >
        Delete
      </button>
    </div>
  );
}

export default ObjectItem;
