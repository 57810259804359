// client/src/components/NoteItem.js

import React from 'react';
import { Link } from 'react-router-dom';

function NoteItem({ note }) {
  return (
    <div>
      <Link to={`/notes/${note.id}`} className="text-blue-500 hover:underline">
        Open Note
      </Link>
    </div>
  );
}

export default NoteItem;
