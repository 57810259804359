import { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext(null);

export function UserContextProvider({ children }) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get('/auth/status', { withCredentials: true });
                setUser(response.data.user);
            } catch (error) {
                setUser(null);
            }
        };

        fetchUser();
        const interval = setInterval(fetchUser, 1000 * 60 * 2);
        return () => clearInterval(interval);
    }, []);

    const handleLogin = () => {
        window.location.href = '/auth/google';
    };

    const handleLogout = () => {
        window.location.href = '/auth/logout';
    };

    if (!user) {
        return (
            <div className="flex items-center justify-center h-screen">
                <button
                    onClick={handleLogin}
                    className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-700"
                >
                    Login with Google
                </button>
            </div>
        );
    }

    return (
        <UserContext.Provider value={{ user, handleLogin, handleLogout }}>
            {children}
        </UserContext.Provider>
    );
}

export default UserContext;