
export const getLocalDate = () => {
    const date = new Date();
    const localISODate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('.')[0];
    const timezoneOffset = date.getTimezoneOffset();
    const timezoneOffsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
    const timezoneOffsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
    const timezoneSign = timezoneOffset <= 0 ? '+' : '-';

    const finalDate = `${localISODate}${timezoneSign}${timezoneOffsetHours}:${timezoneOffsetMinutes}`;
    return finalDate;
}