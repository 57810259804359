import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useError } from '../../contexts/ErrorContext';
import LifeAreasChart from './LifeAreasChart';
import { Link, useNavigate } from 'react-router-dom';

const DEFAULT_LIFE_AREAS = [
    'Health & Fitness',
    'Career & Work',
    'Relationships',
    'Personal Growth',
    'Finance',
    'Recreation & Fun',
    'Spirituality',
    'Community'
];

function LifeAreasModule() {
    const [moduleData, setModuleData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedAreas, setSelectedAreas] = useState([]);
    const { showError } = useError();
    const [showUninstallConfirm, setShowUninstallConfirm] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchModuleData();
    }, []);

    const fetchModuleData = async () => {
        try {
            const response = await axios.get('/api/objects', { withCredentials: true });
            const objects = response.data.objects;
            const foundModuleData = objects.find(
                obj => obj.type === 'ModuleData' && obj.name === 'LifeAreasModule'
            );
            
            if (foundModuleData) {
                setModuleData(foundModuleData);
                setSelectedAreas(foundModuleData.data.lifeAreas);
            } else {
                const savedAreas = localStorage.getItem('selectedLifeAreas');
                if (savedAreas) {
                    setSelectedAreas(JSON.parse(savedAreas));
                }
            }
        } catch (error) {
            console.error('Error fetching module data:', error);
            showError('Error fetching module data:', error);
        }
        setLoading(false);
    };

    const handleAreaSelection = (area) => {
        const newSelectedAreas = selectedAreas.includes(area) 
            ? selectedAreas.filter(a => a !== area)
            : [...selectedAreas, area];
        
        setSelectedAreas(newSelectedAreas);
        localStorage.setItem('selectedLifeAreas', JSON.stringify(newSelectedAreas));
    };

    const initializeModule = async () => {
        try {
            setLoading(true);
            const response = await axios.post(
                '/api/modules/life-areas/install',
                { lifeAreas: selectedAreas },
                { withCredentials: true }
            );
            setModuleData(response.data.moduleData);
        } catch (error) {
            console.error('Error initializing module:', error);
            showError('Error initializing module:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleUninstall = async (keepData = false) => {
        try {
            setLoading(true);
            await axios.post(
                '/api/modules/life-areas/uninstall',
                { keepData },
                { withCredentials: true }
            );
            
            // Clear local storage
            localStorage.removeItem('selectedLifeAreas');
            
            // Reset state
            setModuleData(null);
            setSelectedAreas([]);
            
            if (!keepData) {
                navigate('/dashboard');
            } else {
                // Refresh the page to show disabled state
                window.location.reload();
            }
        } catch (error) {
            console.error('Error uninstalling module:', error);
            showError('Error uninstalling module');
        } finally {
            setLoading(false);
            setShowUninstallConfirm(false);
        }
    };

    if (loading) {
        return <div>Loading Life Areas Module...</div>;
    }

    if (!moduleData) {
        return (
            <div className="p-4">
                <h2 className="text-xl font-bold mb-4">Select Your Life Areas</h2>
                <p className="mb-4">Choose the areas of life you want to track and improve:</p>
                
                <div className="grid grid-cols-2 gap-4 mb-6">
                    {DEFAULT_LIFE_AREAS.map((area) => (
                        <div key={area} className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                id={area}
                                checked={selectedAreas.includes(area)}
                                onChange={() => handleAreaSelection(area)}
                                className="form-checkbox"
                            />
                            <label htmlFor={area}>{area}</label>
                        </div>
                    ))}
                </div>

                <button
                    onClick={initializeModule}
                    disabled={selectedAreas.length === 0}
                    className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-400"
                >
                    Initialize Life Areas Module
                </button>
            </div>
        );
    }

    const uninstallButtons = (
        <div className="mt-8 border-t pt-4">
            <h3 className="text-xl font-bold mb-4 text-red-600">Danger Zone</h3>
            {showUninstallConfirm ? (
                <div className="space-y-4">
                    <p className="text-gray-700">Are you sure you want to uninstall the Life Areas Module?</p>
                    <div className="space-x-4">
                        <button
                            onClick={() => handleUninstall(true)}
                            className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
                        >
                            Uninstall But Keep Data
                        </button>
                        <button
                            onClick={() => handleUninstall(false)}
                            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                        >
                            Uninstall Completely
                        </button>
                        <button
                            onClick={() => setShowUninstallConfirm(false)}
                            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            ) : (
                <button
                    onClick={() => setShowUninstallConfirm(true)}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                >
                    Uninstall Module
                </button>
            )}
        </div>
    );

    return (
        <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Life Areas Overview</h2>
            
            <div className="mb-8">
                <LifeAreasChart 
                    lifeAreas={moduleData.data.lifeAreas}
                    scores={moduleData.data.scores}
                    scoreHistory={moduleData.data.scoreHistory}
                />
            </div>

            <div className="mb-4">
                <h3 className="font-semibold mb-2">Watcher Jobs</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    {moduleData.data.lifeAreas.map((area) => (
                        <Link
                            key={`job-${area}`}
                            to={`/jobs/${moduleData.data.jobIds[area]}`}
                            className="text-sm text-blue-500 hover:text-blue-700"
                        >
                            View {area} Watcher Job
                        </Link>
                    ))}
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {moduleData.data.lifeAreas.map((area) => (
                    <div key={area} className="border p-4 rounded shadow">
                        <h3 className="font-bold">{area}</h3>
                        <div className="mt-2">
                            <span className="font-semibold">Current Score: </span>
                            {moduleData.data.scores[area] !== null 
                                ? moduleData.data.scores[area] + '/10'
                                : 'Not yet scored'}
                        </div>
                        <Link
                            to={`/conversations/${moduleData.data.agentIds[area]}`}
                            className="mt-2 bg-green-500 text-white px-3 py-1 rounded text-sm hover:bg-green-600 transition-colors"
                        >
                            Talk to Watcher
                        </Link>
                        <button
                            onClick={async () => {
                                try {
                                    await axios.post(
                                        `/api/jobs/${moduleData.data.jobIds[area]}/test-run`,
                                        {},
                                        { withCredentials: true }
                                    );
                                    // Refresh module data to show new scores
                                    await fetchModuleData();
                                } catch (error) {
                                    console.error('Error test running job:', error);
                                    showError('Error test running job');
                                }
                            }}
                            className="mt-2 ml-2 bg-blue-500 text-white px-3 py-1 rounded text-sm hover:bg-blue-600 transition-colors"
                        >
                            Run Score Check
                        </button>
                    </div>
                ))}
            </div>
            {uninstallButtons}
        </div>
    );
}

export default LifeAreasModule; 