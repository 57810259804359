import React from 'react';
import { Link } from 'react-router-dom';

const JobItem = ({ job }) => {
    const getNextRunText = () => {
        if (job.enabled === false) {
            return 'Next run: Job is disabled';
        }
        if (!job.nextRunDate) {
            return 'Next run: No upcoming runs';
        }
        return `Next run: ${job.nextRunDate}`;
    };

    const getProgressText = () => {
        return `Run ${job.runCount} of ${job.repeats}`;
    };

    return (
        <div>
            <div className="text-sm text-gray-600">
                <p>Frequency: Every {job.frequencyInHours} hours</p>
                <p>{getProgressText()}</p>
                <p>{getNextRunText()}</p>
                <p>Nodes: {job.nodes.length}</p>
                {job.enabled === false && <p className="text-red-500">⚠️ Disabled</p>}
                {typeof job.error === 'string' && <p style={{ color: 'red' }}>Error: {job.error}</p>}
            </div>
            <Link to={`/jobs/${job.id}`} className="text-blue-500 hover:underline">
                View Job
            </Link>
        </div>
    );
};

export default JobItem;
