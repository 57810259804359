import React, { useEffect, useState } from 'react';
import { useNotifications } from '../contexts/NotificationContext';
import { onMessageListener, requestNotificationPermission } from '../utils/firebaseUtils';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default function Notifications() {
  const { notifications, addNotification, removeNotification } = useNotifications();
  const [showPermissionPrompt, setShowPermissionPrompt] = useState(false);

  useEffect(() => {
    // Check if we've already asked for permission
    const hasAskedPermission = localStorage.getItem('hasAskedNotificationPermission');
    if (!hasAskedPermission) {
      setShowPermissionPrompt(true);
    } else {
      setupNotifications();
    }
  }, []);

  const setupNotifications = async () => {
    const token = await requestNotificationPermission();
    if (token) {
      // Generate a unique device ID or get it from localStorage
      let deviceId = localStorage.getItem('deviceId');
      if (!deviceId) {
        deviceId = `web_${uuidv4()}`;
        localStorage.setItem('deviceId', deviceId);
      }

      // Send the token to the server
      await axios.post('/api/notifications/save-fcm-token', { 
        token,
        deviceId
      });
    }
  };

  const handlePermissionRequest = async () => {
    setShowPermissionPrompt(false);
    localStorage.setItem('hasAskedNotificationPermission', 'true');
    await setupNotifications();
  };

  useEffect(() => {
    onMessageListener().then((payload) => {
      console.log('Received foreground message:', payload);
      addNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    });
  }, [addNotification]);

  return (
    <>
      {showPermissionPrompt && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-white p-4 rounded-lg shadow-lg z-50">
          <p className="mb-4">Would you like to receive notifications?</p>
          <div className="flex justify-end space-x-2">
            <button 
              onClick={() => setShowPermissionPrompt(false)}
              className="px-4 py-2 bg-gray-200 rounded"
            >
              Not now
            </button>
            <button 
              onClick={handlePermissionRequest}
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Allow
            </button>
          </div>
        </div>
      )}
      <div className="fixed top-4 right-4 z-50 space-y-2">
        {notifications.map(({ id, title, body }) => (
          <div
            key={id}
            className="bg-white rounded-lg shadow-lg p-4 max-w-sm animate-slide-in"
            onClick={() => removeNotification(id)}
          >
            <h4 className="font-bold text-gray-800">{title}</h4>
            <p className="text-gray-600">{body}</p>
          </div>
        ))}
      </div>
    </>
  );
}