import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLocalDate } from '../utils/webUtils';
import axios from 'axios';
import { useError } from '../contexts/ErrorContext';

const ObjectPage = ({ object, setObject, objectId, saveEdits }) => {
  const navigate = useNavigate();
  const { showError } = useError();

  const loadObject = async () => {
    if (objectId !== 'new') {
      await axios
        .get(`/api/objects/${objectId}`, { withCredentials: true })
        .then((response) => {
          setObject(response.data.object);
        })
        .catch((error) => {
          console.error('Error fetching object:', error);
          showError('Error fetching object:', error);
        });
    }
  };

  useEffect(() => {
    if (objectId === 'new') {
      axios
        .post('/api/objects', 
          { date: getLocalDate() }, 
          { withCredentials: true }
        )
        .then((response) => {
          const newObject = response.data.object;
          navigate(`/objects/${newObject.id}`, { replace: true });
        })
        .catch((error) => {
          console.error('Error creating new object:', error);
          showError('Error creating new object:', error);
        });
    } else {
      loadObject();
    }
  }, [objectId, navigate]);

  const handleChange = (field, value) => {
    setObject({
      ...object,
      [field]: value,
    });
  };

  const saveObject = async () => {
    await saveEdits();
    alert('Object saved successfully!');
  };

  if (!object) {
    return <div>Loading...</div>;
  }

  return (
    <div className="object-page">
      <h1>
        <input
          type="text"
          value={object.name}
          onChange={(e) => handleChange('name', e.target.value)}
          placeholder="Object Title"
          className="border p-1 w-full mb-4"
        />
      </h1>
      <div className="mb-4">
        <label className="block mb-2 font-bold">Type:</label>
        <input
          type="text"
          value={object.type}
          onChange={(e) => handleChange('type', e.target.value)}
          className="w-full p-2 border rounded"
          readOnly
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2 font-bold">Content:</label>
        <textarea
          value={object.content || ''}
          onChange={(e) => handleChange('content', e.target.value)}
          placeholder="Object content..."
          className="w-full p-2 border rounded"
          rows={10}
        />
      </div>
      <div className="mt-4">
        <label className="block mb-2 font-bold">Labels (comma-separated):</label>
        <input
          type="text"
          value={object.labels ? object.labels.join(', ') : ''}
          onChange={(e) => handleChange('labels', e.target.value.split(',').map(label => label.trim()))}
          className="w-full p-2 border rounded"
        />
      </div>
      <button onClick={saveObject} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
        Save Object
      </button>
    </div>
  );
};

export default ObjectPage; 