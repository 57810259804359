// client/src/App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import Canvas from './components/Canvas';
import Dashboard from './components/Dashboard';
import ObjectsPage from './components/ObjectsPage';
import ConversationPage from './components/ConversationPage';
import NotePage from './components/NotePage';
import ObjectPage from './components/ObjectPage';
import ObjectEditor from './components/ObjectEditor';
import { UserContextProvider } from './contexts/UserContext';
import JobPage from './components/JobPage';
import { register } from './serviceWorkerRegistration.js';
import { NotificationProvider } from './contexts/NotificationContext';
import Notifications from './components/Notifications';
import { ErrorProvider } from './contexts/ErrorContext';
import ErrorDisplay from './components/ErrorDisplay';

function App() {
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [showReload, setShowReload] = useState(false);

  useEffect(() => {
    // Register service worker update handler
    if ('serviceWorker' in navigator) {
      const handleServiceWorkerUpdate = (registration) => {
        setWaitingWorker(registration.waiting);
        setShowReload(true);
      };

      register({
        onUpdate: handleServiceWorkerUpdate,
      });
    }
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  };

  const handleLogout = () => {
    window.location.href = '/auth/logout';
  };

  return (
    <UserContextProvider>
      <ErrorProvider>
        <NotificationProvider>
          <Router>
            <nav className="p-4 bg-gray-800 text-white flex justify-between items-center">
              <div className="flex gap-4">
                <Link to="/dashboard">Dashboard</Link>
                <Link to="/objects">Objects</Link>
                <Link to="/canvas">Canvas</Link>
              </div>
              <div>
                <button
                  onClick={handleLogout}
                  className="bg-red-500 text-white px-4 py-2 rounded"
                >
                  Logout
                </button>
              </div>
            </nav>
            <Notifications />
            <main className="container mx-auto px-4 py-4">
              <ErrorDisplay />
              <Routes>
                <Route path="/canvas" element={<Canvas />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/objects" element={<ObjectsPage />} />
                <Route path="/notes/:objectId" element={
                  <ObjectEditor component={(object, setObject, objectId, saveEdits, isSaving) =>
                    <NotePage
                      note={object}
                      setNote={setObject}
                      noteId={objectId}
                      saveEdits={saveEdits}
                      isSaving={isSaving}
                    />}
                  />}
                />
                <Route path="/conversations/:objectId" element={
                  <ObjectEditor component={(object, setObject, objectId, saveEdits, isSaving) =>
                    <ConversationPage
                      conversation={object}
                      setConversation={setObject}
                      conversationId={objectId}
                      saveEdits={saveEdits}
                      isSaving={isSaving}
                    />}
                  />}
                />
                <Route path="/jobs/:objectId" element={
                  <ObjectEditor component={(object, setObject, objectId, saveEdits, isSaving) =>
                    <JobPage
                      job={object}
                      setJob={setObject}
                      jobId={objectId}
                      saveEdits={saveEdits}
                      isSaving={isSaving}
                    />}
                  />}
                />
                <Route path="/objects/:objectId" element={
                  <ObjectEditor component={(object, setObject, objectId, saveEdits, isSaving) =>
                    <ObjectPage
                      object={object}
                      setObject={setObject}
                      objectId={objectId}
                      saveEdits={saveEdits}
                      isSaving={isSaving}
                    />}
                  />}
                />
                <Route path="/" element={<Navigate to="/dashboard" />} />
              </Routes>
            </main>
            <footer className="bg-gray-800 text-white text-center py-4">
              &copy; {new Date().getFullYear()} Personal Canvas
            </footer>
          </Router>
        </NotificationProvider>
      </ErrorProvider>
    </UserContextProvider>
  );
}

export default App;
