// client/src/components/Canvas.js
import React, { useState, useEffect } from 'react';
import LifeAreasModule from './Modules/LifeAreasModule';
import ClosePeopleModule from './Modules/ClosePeopleModule';
import { useError } from '../contexts/ErrorContext';
import axios from 'axios';

function Canvas() {
  const [availableModules] = useState([
    {
      name: 'LifeAreasModule',
      description: 'Track and improve different areas of your life with AI Watchers',
      component: LifeAreasModule
    },
    {
      name: 'ClosePeopleModule',
      description: 'Track and improve your relationships with people close to you',
      component: ClosePeopleModule
    }
  ]);
  const [activeModules, setActiveModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showError } = useError();

  useEffect(() => {
    loadActiveModules();
  }, []);

  const loadActiveModules = async () => {
    try {
      const response = await axios.get('/api/objects', { withCredentials: true });
      const objects = response.data.objects;
      const moduleData = objects.filter(obj => obj.type === 'ModuleData');

      // Find which modules are already initialized
      const activeModule = moduleData.map(md => {
        return availableModules.find(m => m.name === md.name);
      }).filter(Boolean);
      
      setActiveModules(activeModule);
    } catch (error) {
      console.error('Error loading active modules:', error);
      showError('Error loading active modules');
    }
    setLoading(false);
  };

  if (loading) {
    return <div>Loading Canvas...</div>;
  }

  return (
    <div className="p-4">
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Available Modules</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {availableModules.map(module => (
            <div key={module.name} className="border p-4 rounded shadow">
              <h3 className="font-bold">{module.name}</h3>
              <p className="text-gray-600 mb-4">{module.description}</p>
              {!activeModules.includes(module) && (
                <button
                  onClick={() => setActiveModules([...activeModules, module])}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Add
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="space-y-8">
        {activeModules.map(module => {
          const ModuleComponent = module.component;
          return (
            <div key={module.name} className="border rounded-lg p-4 shadow">
              <h2 className="text-xl font-bold mb-4">{module.name}</h2>
              <ModuleComponent />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Canvas;
