import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ClosePeopleModule.css';
import { useNavigate } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

function ClosePeopleModule() {
  const [people, setPeople] = useState([]);
  const [potentialMentions, setPotentialMentions] = useState({});
  const [loading, setLoading] = useState(false);
  const [moduleData, setModuleData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPeopleData();
  }, []);

  async function fetchPeopleData() {
    try {
      setLoading(true);
      const response = await axios.get('/api/modules/closePeople/data', {
        withCredentials: true,
      });
      let moduleData = response.data;
      if (moduleData && moduleData.data) {
        setModuleData(moduleData.data);
        const peopleData = Object.values(moduleData.data.people || {});
        peopleData.sort((a, b) => b.interactions - a.interactions);
        setPeople(peopleData);
        setPotentialMentions(moduleData.data.potentialMentions || {});
      } else {
        setModuleData(null);
        setPeople([]);
        setPotentialMentions({});
      }
    } catch (error) {
      console.error('Error fetching people data:', error);
      if (error.response?.status === 404) {
        setModuleData(null);
        setPeople([]);
        setPotentialMentions({});
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdatePerson(personName, updatedDetails) {
    try {
      await axios.post(
        '/api/modules/closePeople/updatePerson',
        { personName, details: updatedDetails },
        { withCredentials: true }
      );
      // Refresh people data
      await fetchPeopleData();
    } catch (error) {
      console.error('Error updating person details:', error);
      // Handle error as needed
    }
  }

  async function handleInstall() {
    try {
      setLoading(true);
      await axios.post('/api/modules/closePeople/install', {}, { withCredentials: true });
      await fetchPeopleData();
    } catch (error) {
      console.error('Error installing ClosePeople module:', error);
    } finally {
      setLoading(false);
    }
  }

  async function handleUninstall(keepData = false) {
    try {
      setLoading(true);
      await axios.post(
        '/api/modules/closePeople/uninstall',
        { keepData },
        { withCredentials: true }
      );
      // Handle post-uninstall behavior, e.g., reset state or redirect
      setModuleData(null);
      setPeople([]);
      setPotentialMentions({});
    } catch (error) {
      console.error('Error uninstalling ClosePeople module:', error);
      // Handle error as needed
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <div>Loading Close People Module...</div>;
  }

  if (!moduleData) {
    return (
      <div className="p-4">
        <h2 className="text-xl font-bold mb-4">Close People Module</h2>
        <p className="mb-4">This module helps you track and understand your relationships through calendar interactions.</p>
        
        <button
          onClick={handleInstall}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Install Close People Module
        </button>
      </div>
    );
  }

  return (
    <div className="close-people-module">
      <button onClick={() => handleUninstall(false)} className="uninstall-button">Uninstall Module</button>
      <h1>Close People</h1>

      <NameReplacementTool people={people} />
      <PotentialMentionsSection mentions={potentialMentions} />

      <h2>People</h2>
      <PeopleList people={people} onUpdate={handleUpdatePerson} />

      <InteractionsChart people={people} />
    </div>
  );
}

function PersonCard({ person, onUpdate }) {
  const [editing, setEditing] = useState(false);
  const [showEvents, setShowEvents] = useState(false);
  const [details, setDetails] = useState({
    relationship: person.relationship,
    description: person.description,
    traits: person.traits.join(', '),
  });

  function handleSave() {
    onUpdate(person.name, {
      relationship: details.relationship,
      description: details.description,
      traits: details.traits.split(',').map((trait) => trait.trim()),
    });
    setEditing(false);
  }

  return (
    <div className="person-card">
      <h2>{person.name}</h2>
      <div className="info">
        <label>Interactions:</label> {person.interactions}
      </div>
      <div className="info">
        <label>Last Interaction:</label> {new Date(person.lastInteraction).toLocaleDateString()}
      </div>
      {editing ? (
        <div className="edit-section">
          <label>
            Relationship:
            <input
              type="text"
              value={details.relationship}
              onChange={(e) => setDetails({ ...details, relationship: e.target.value })}
            />
          </label>
          <label>
            Description:
            <textarea
              value={details.description}
              onChange={(e) => setDetails({ ...details, description: e.target.value })}
            />
          </label>
          <label>
            Traits:
            <input
              type="text"
              value={details.traits}
              onChange={(e) => setDetails({ ...details, traits: e.target.value })}
            />
          </label>
          <button className="save-button" onClick={handleSave}>Save</button>
        </div>
      ) : (
        <div className="details-section">
          <div className="info"><label>Relationship:</label> {person.relationship || 'N/A'}</div>
          <div className="info"><label>Description:</label> {person.description || 'N/A'}</div>
          <div className="info"><label>Traits:</label> {person.traits.length > 0 ? person.traits.join(', ') : 'N/A'}</div>
          <button className="edit-button" onClick={() => setEditing(true)}>Edit</button>
          <button 
            className="show-events-button" 
            onClick={() => setShowEvents(!showEvents)}
          >
            {showEvents ? 'Hide Events' : 'Show Events'}
          </button>
        </div>
      )}
      {showEvents && (
        <div className="events-section">
          <h3>Events History</h3>
          <div className="events-list">
            {person.events.map((event, index) => (
              <div key={index} className="event-item">
                <div className="event-summary">{event.summary}</div>
                <div className="event-date">{new Date(event.date).toLocaleDateString()}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function PeopleList({ people, onUpdate }) {
  const [showCount, setShowCount] = useState(6);
  const hasMore = people.length > showCount;

  return (
    <div>
      <div className="people-list">
        {people.slice(0, showCount).map((person) => (
          <PersonCard key={person.name} person={person} onUpdate={onUpdate} />
        ))}
      </div>
      {hasMore && (
        <button 
          className="show-more-button"
          onClick={() => setShowCount(prev => prev + 6)}
        >
          Show More...
        </button>
      )}
    </div>
  );
}

function PotentialMentionsSection({ mentions }) {
  if (!mentions || Object.keys(mentions).length === 0) {
    return null;
  }

  return (
    <div className="potential-mentions-section">
      <h2>Potential People Mentions</h2>
      <div className="mentions-list">
        {Object.entries(mentions).map(([eventId, event]) => (
          <div key={eventId} className="mention-card">
            <h3>{event.summary}</h3>
            {event.description && <p>{event.description}</p>}
            <div className="potential-names">
              <strong>Potential Names Found:</strong>
              <ul>
                {event.potentialNames.map((name, index) => (
                  <li key={index}>{name}</li>
                ))}
              </ul>
            </div>
            {!event.reviewed && <span className="badge">New</span>}
          </div>
        ))}
      </div>
    </div>
  );
}

function InteractionsChart({ people }) {
  const data = [...people].sort((a, b) => b.interactions - a.interactions).map(person => ({
    name: person.name,
    interactions: person.interactions,
  }));

  return (
    <div className="interactions-chart">
      <h2>Interactions Chart</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} layout="vertical" margin={{ left: 50 }}>
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" />
          <Tooltip />
          <Bar dataKey="interactions" fill="#007acc" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

function NameReplacementTool({ people }) {
  const [searchName, setSearchName] = useState('');
  const [replacementName, setReplacementName] = useState('');
  const [matchingEvents, setMatchingEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState(new Set());
  const [loading, setLoading] = useState(false);

  async function handleSearch() {
    try {
      setLoading(true);
      const response = await axios.get(`/api/modules/closePeople/searchEvents`, {
        params: { searchName },
        withCredentials: true,
      });
      setMatchingEvents(response.data.events);
    } catch (error) {
      console.error('Error searching events:', error);
    } finally {
      setLoading(false);
    }
  }

  async function handleReplace() {
    try {
      setLoading(true);
      await axios.post('/api/modules/closePeople/replaceNames', {
        oldName: searchName,
        newName: replacementName,
        events: Array.from(selectedEvents).map(eventId => ({
            eventId,
            calendarId: matchingEvents.find(e => e.id === eventId).calendarId
          }))
      }, { withCredentials: true });
      
      // Clear states after successful replacement
      setMatchingEvents([]);
      setSelectedEvents(new Set());
      setSearchName('');
      setReplacementName('');
    } catch (error) {
      console.error('Error replacing names:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="name-replacement-tool">
      <h2>Name Replacement Tool</h2>
      <div className="search-section">
        <input
          type="text"
          placeholder="Search name (e.g., mom, chrisfromwork)"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Replace with (e.g., @mama, @christstevens)"
          value={replacementName}
          onChange={(e) => setReplacementName(e.target.value)}
        />
        <button 
          onClick={handleSearch}
          disabled={!searchName || loading}
          className="search-button"
        >
          Search Events
        </button>
      </div>

      {matchingEvents.length > 0 && (
        <div className="matching-events">
          <h3>Matching Events</h3>
          <div className="events-list">
            {matchingEvents.map((event) => (
              <div key={event.id} className="event-item">
                <button
                  onClick={() => setSelectedEvents(prev => {
                    const newSet = new Set(prev);
                    if (newSet.has(event.id)) {
                      newSet.delete(event.id);
                    } else {
                      newSet.add(event.id);
                    }
                    return newSet;
                  })}
                  className={`toggle-button ${selectedEvents.has(event.id) ? 'selected' : ''}`}
                >
                  {selectedEvents.has(event.id) ? '-' : '+'}
                </button>
                <div className="event-details">
                  <div className="event-summary">{event.summary}</div>
                  <div className="event-description">{event.description}</div>
                  <div className="event-date">
                    {new Date(event.start.dateTime || event.start.date).toLocaleDateString()}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={handleReplace}
            disabled={selectedEvents.size === 0 || !replacementName || loading}
            className="replace-button"
          >
            Replace in Selected Events
          </button>
        </div>
      )}
    </div>
  );
}

export default ClosePeopleModule; 