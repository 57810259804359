// client/src/components/AIChat.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import ChatMessage from './ChatMessage';
import UserContext from '../contexts/UserContext';
import { uploadFile } from '../utils/firebaseUtils';
import { getLocalDate } from '../utils/webUtils';
import { useError } from '../contexts/ErrorContext';

const AIChat = ({
  conversation,
  conversationId,
  onUpdateMessage,
  onDeleteMessage,
  refreshConversation,
  addEmptyMessage,
  onToggleFreezePoint,
  isSaving
}) => {
  const [inputText, setInputText] = useState('');
  const [inputImages, setInputImages] = useState([]);
  const [chatMessages, setChatMessages] = useState(conversation.messages ?? []);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const [freezePoint, setFreezePoint] = useState(conversation.freezePoint ?? null);
  const inputRef = useRef(null);
  const { user } = useContext(UserContext);
  const { showError } = useError();

  useEffect(() => {
    setChatMessages(conversation.messages ?? []);
    setFreezePoint(conversation.freezePoint ?? null);
  }, [conversation]);

  useEffect(() => {
    const handlePaste = async (event) => {
      event.preventDefault();
      // console.log('Pasting...', event);

      const clipboardItems = typeof navigator?.clipboard?.read === 'function' ? await navigator.clipboard.read() : event.clipboardData.files;

      let imageFiles = [];
      let text = '';
      const createFile = (blob) => new File([blob], `image-${Date.now()}.png`, { type: blob.type });
      for (const clipboardItem of clipboardItems) {
        let blob;
        if (clipboardItem.type?.startsWith('image/')) {
          blob = clipboardItem
          imageFiles.push(createFile(blob));
        } else if (clipboardItem.type?.startsWith('text/')) {
          let clipboardText = await clipboardItem.getType('text/plain');
          if (clipboardText === '\n') clipboardText = '';
          text += clipboardText;
        } else {
          let imageTypes = clipboardItem.types?.filter((type) => type.startsWith("image/"));
          if (imageTypes.length) {
            blob = await clipboardItem.getType(imageTypes[0]);
            imageFiles.push(createFile(blob));
          }
          else if (clipboardItem.types?.filter((type) => type === 'text/plain').length) {
            blob = await clipboardItem.getType('text/plain');
            let clipboardText = await blob.text();
            if (clipboardText === '\n') clipboardText = '';
            text += clipboardText;
          }
          else if (clipboardItem.types?.filter((type) => type === 'text/html').length) {
            blob = await clipboardItem.getType('text/html');
            let clipboardText = await blob.text();
            if (clipboardText === '\n') clipboardText = '';
            text += clipboardText;
          }
        }
      }
      setInputImages([...inputImages, ...imageFiles]);
      setInputText(inputText + text);
    };


    const inputElement = inputRef.current;
    inputElement.addEventListener('paste', handlePaste);

    return () => {
      inputElement.removeEventListener('paste', handlePaste);
    };
  }, [inputImages, inputText]);

  const getImageURL = async (image) => {
    return await uploadFile(user, image);
  };

  const handleSend = async () => {
    if (!inputText.trim() && (inputImages.length == 0)) return;

    setIsAwaitingResponse(true);

    try {
      // If there's a freeze point, let the server handle the truncation
      let newMessage = { role: 'user', content: [{ type: 'text', text: inputText.trim() }] };

      if (inputImages.length) {
        const imageUrls = await Promise.all(inputImages.map(getImageURL));
        newMessage.content.push(...imageUrls.map((url) => ({ type: 'image_url', image_url: { url } })));
      }

      // Don't manipulate messages locally, let the server handle it
      setInputText('');
      setInputImages([]);

      await axios.post(
        `/api/ai`,
        { message: newMessage, conversationId, date: getLocalDate() },
        { withCredentials: true, timeout: 5 * 60 * 1000 }
      );
      await refreshConversation();
    } catch (error) {
      console.error('Error sending message:', error);
      showError('Error sending message:', error);
    }
    setIsAwaitingResponse(false);
  };

  const generateContinuation = async () => {
    setIsAwaitingResponse(true);

    try {
      await axios.post(
        `/api/ai`,
        { message: null, conversationId, date: getLocalDate() },
        { withCredentials: true, timeeout: 5 * 60 * 1000 }
      );
      await refreshConversation();
    } catch (error) {
      console.error('Error generating continuation:', error);
      showError('Error generating continuation:', error);
    }
    setIsAwaitingResponse(false);
  };

  // Toggle freeze point
  const toggleFreezePoint = async () => {
    try {
      const newFreezePoint = freezePoint === null ? chatMessages.length : null;
      setFreezePoint(newFreezePoint);
      onToggleFreezePoint(newFreezePoint);
    } catch (error) {
      console.error('Error toggling freeze point:', error);
      showError('Error toggling freeze point');
    }
  };

  // Add this after the messages rendering
  const renderFreezeButton = () => (
    <div key="freezeButton" className="flex justify-center my-4">
      <button
        onClick={toggleFreezePoint}
        className={`px-4 py-2 rounded ${freezePoint !== null
          ? 'bg-yellow-200 font-bold w-full text-center'
          : 'bg-yellow-500 hover:bg-yellow-600 text-white'
          }`}
      >
        {freezePoint !== null ? 'Conversation Frozen Until Here' : 'Freeze Conversation Here'}
      </button>
    </div>
  );

  const messagesList = chatMessages.map((message, index) => (
    <ChatMessage
      key={index}
      index={index}
      message={message}
      onUpdateMessage={onUpdateMessage}
      onDeleteMessage={onDeleteMessage}
    />
  ));
  if (freezePoint !== null) {
    for (let i = 0; i <= messagesList.length; i++) {
      if (i === freezePoint) {
        messagesList.splice(i, 0, renderFreezeButton());
        break;
      }
    }
  } else {
    messagesList.push(renderFreezeButton());
  }

  return (
    <div className="mt-4">
      <div className="flex flex-col rounded-lg p-4 border border-neutral-300">
        {messagesList}
        {isAwaitingResponse && (
          <div role="status">
            <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        )}
      </div>

      {inputImages.length > 0 && (
        <div className="flex flex-wrap mt-4">
          {inputImages.map((image, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center"
            >
              <img
                src={URL.createObjectURL(image)}
                alt="Uploaded"
                className="w-24 h-24 mr-2 mb-2"
                onClick={async () => {
                  let url = await getImageURL(image);
                  navigator.clipboard.write([new ClipboardItem({ 'text/plain': url })]);
                }}
              />
              <button
                onClick={() => setInputImages(inputImages.filter((_, i) => i !== index))}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      )}

      {(!isSaving || isAwaitingResponse) ? (<div>
        <div className="flex items-center mt-4">
          <textarea
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSend()}
            className="flex-grow border p-2 rounded mr-2"
            placeholder="Type your message..."
            ref={inputRef}
          />
          <input
            type="file"
            onChange={(e) => setInputImages([...inputImages, ...([...e.target.files].filter((file) => file.type.startsWith('image/')))])}
            className="mr-2"
            multiple="multiple"
            placeholder='Upload images'
            accept="image/*"
          />
          <button
            onClick={handleSend}
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={isAwaitingResponse}>
            Send
          </button>
        </div>

        {!inputText.length && !inputImages.length && (
          <div className="flex items-center mt-4">
            <button
              onClick={generateContinuation}
              className="bg-green-500 text-white px-4 py-2 rounded"
              disabled={isAwaitingResponse}
            >
              Generate continuation
            </button>

            <button
              onClick={addEmptyMessage}
              className="bg-gray-500 text-white px-4 py-2 rounded"
              disabled={isAwaitingResponse}
            >
              Add empty message
            </button>
          </div>
        )}
      </div>) : (<div>Saving...</div>)}
    </div>
  );
};

export default AIChat;
