import React, { useState } from 'react';
import { useError } from '../contexts/ErrorContext';

const ErrorDisplay = () => {
    const { error, clearError } = useError();
    const [showPopUp, setShowPopUp] = useState(true);

    if (!error) return null;

    setTimeout(() => {
        setShowPopUp(false);
    }, 15000);

    return (
        <>
            { showPopUp && <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50">
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold mr-2">Error:</strong>
                    <span className="block sm:inline">{error.message}</span>
                    {error.details && (
                        <div className="mt-2 text-sm">{JSON.stringify(error.details)}</div>
                    )}
                    <button
                        onClick={() => setShowPopUp(false)}
                        className="absolute top-0 right-0 px-4 py-3"
                    >
                        <span className="sr-only">Close</span>
                        <svg className="h-4 w-4 fill-current" role="button" viewBox="0 0 20 20">
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
            </div>}

            {!showPopUp && <div style={{ color: 'red', position: 'relative' }}>
                <strong className="font-bold mr-2">Error:</strong>
                <span className="block sm:inline">{error.message}</span>
                {error.details && (
                    <div className="mt-2 text-sm">{JSON.stringify(error.details)}</div>
                )}
                <button
                    onClick={clearError}
                    className="absolute top-0 right-0 px-4 py-3"
                >
                    <span className="sr-only">Close</span>
                    <svg className="h-4 w-4 fill-current" role="button" viewBox="0 0 20 20">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </div>}
        </>
    );
};

export default ErrorDisplay; 