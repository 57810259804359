// client/src/components/Dashboard.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();

  const startConversation = () => {
    navigate('/conversations/new');
  };

  const createNewNote = () => {
    navigate('/notes/new');
  };

  const createNewJob = () => {
    navigate('/jobs/new');
  };

  return (
    <div className="dashboard">
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
      <div className="flex space-x-4">
        <button
          onClick={startConversation}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Start a New Conversation
        </button>
        <button
          onClick={createNewNote}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Create a New Note
        </button>
        <button
          onClick={createNewJob}
          className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
        >
          Create a New Job
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
