import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
);

function LifeAreasChart({ lifeAreas, scores, scoreHistory }) {
    const colors = [
        'red',
        'blue',
        'green',
        'purple',
        'orange',
        'yellow',
        'gray',
        'pink'
    ];

    const chartData = {
        datasets: lifeAreas.map((area, index) => ({
            label: area,
            data: (scoreHistory[area] || []).map(entry => ({
                x: new Date(entry.date),
                y: entry.score
            })),
            borderColor: colors[index % colors.length],
            tension: 0.1,
            fill: false,
            pointRadius: 5,
            pointHoverRadius: 8
        }))
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour',
                    displayFormats: {
                        hour: 'MMM d, HH:mm'
                    }
                },
                ticks: {
                    source: 'data',
                    autoSkip: true,
                    maxRotation: 45,
                    callback: function(value) {
                        return format(new Date(value), 'MMM d, HH:mm');
                    }
                },
                title: {
                    display: true,
                    text: 'Time'
                }
            },
            y: {
                beginAtZero: true,
                max: 10,
                ticks: {
                    stepSize: 1
                },
                title: {
                    display: true,
                    text: 'Score'
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    padding: 20,
                    usePointStyle: true,
                    pointStyle: 'circle'
                }
            },
            tooltip: {
                callbacks: {
                    title: (context) => {
                        return format(new Date(context[0].raw.x), 'MMM d, HH:mm');
                    },
                    label: (context) => {
                        const dataPoint = scoreHistory[context.dataset.label][context.dataIndex];
                        return `${context.dataset.label}: ${dataPoint.score}/10`;
                    },
                    afterLabel: (context) => {
                        const dataPoint = scoreHistory[context.dataset.label][context.dataIndex];
                        return dataPoint.explanation.split('\n');
                    }
                }
            }
        },
        interaction: {
            intersect: false,
            mode: 'nearest'
        }
    };

    return (
        <div className="w-full h-96">
            <Line data={chartData} options={options} />
        </div>
    );
}

export default LifeAreasChart; 